import React, { useState } from "react";
import { colors, FilterTextField, PopTip } from "bild-ui";
import { makeStyles, createStyles, useTheme } from "@mui/styles";
import { Grid, Badge } from "@mui/material";

const useStyles = makeStyles((theme: useTheme) =>
  createStyles({
    menu: {},
    menuItem: {
      padding: "0.75rem 1rem",
      "&:hover": { backgroundColor: colors.gray, cursor: "pointer" }
    },
    name: {
      overflow: "hidden",
      display: "block",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      "&> span": {
        top: "5px",
        right: "10px"
      }
    },
    active: {
      backgroundColor: colors.lightBlue,
      color: colors.white,
      fontWeight: "bold",
      "&:hover": { backgroundColor: colors.lightBlue, cursor: "pointer" }
    },
    archiveItems: { backgroundColor: colors.darkerGray, color: colors.white, fontWeight: "bold", textAlign: "center", maxHeight: "4rem" },
    newItem: { backgroundColor: colors.darkGray, color: colors.white, fontWeight: "bold", textAlign: "center", maxHeight: "4rem" },
    newItem2: { backgroundColor: colors.darkerGray },
    items: {
      overflowY: "auto",
      [theme.breakpoints.down('md')]: {
        maxHeight: "calc(88vh - 6rem) !important"
      },
      [theme.breakpoints.up("sm")]: {
        maxHeight: "calc(100vh - 6rem)"
      }
    }
  })
);

function ManagedMenu({ items, favoriteItems, archiveItems, currentItem, setCurrentItem, newItem, newItemText, newItem2, newItem2Text, showArchives, toggleShowArchives }) {
  const cls = useStyles();
  const [filter, setFilter] = useState("");

  return (
    <Grid container direction="column" justifyContent="space-between" alignItems="stretch" className={cls.menu}>
      <Grid item>
        <FilterTextField
          value={filter}
          placeholder="Filter"
          onChange={e => setFilter(e.target.value)}
          onClear={() => {
            setFilter("");
          }}
          wrapperPadding={"0"}
          fullWidth
        />
      </Grid>
      <Grid container item xs direction="row" justifyContent="flex-start" alignItems="flex-start" className={cls.items}>
        <Grid container item xs={12}>
          {items
          .filter(x =>
            x.name.toLowerCase().includes(filter.toLowerCase()) ||
            x.users.some(u => u.name.toLowerCase().includes(filter.toLowerCase())) ||
            x.paths.some(p => p.name.toLowerCase().includes(filter.toLowerCase())) ||
            x.paths.some(p => p.subtitle && p.subtitle.toLowerCase().includes(filter.toLowerCase()))
          )
          .filter(x => showArchives ? true : !archiveItems.includes(x.id))
          .map(x => {
            return (
              <Grid
                item
                xs={12}
                onClick={() => {
                  setCurrentItem(x.id);
                }}
                key={x.id}
                className={`${cls.menuItem} ${currentItem === x.id ? cls.active : ""}`}
              >
                <PopTip text={x.name.length > 19 ? x.name : null}>
                  <Badge color="secondary" variant="dot" invisible={x.read === undefined ? true : x.read} className={cls.name}>
                    {archiveItems && archiveItems.length > 0 && archiveItems.includes(x.id) && (
                      <><i className={`fas fa-archive fa-fw fa-xs`} /> &nbsp;</>
                    )}
                    {favoriteItems && favoriteItems.length > 0 && favoriteItems.includes(x.id) && (
                      <><i className={`fas fa-star fa-fw fa-xs`} /> &nbsp;</>
                    )}
                    {x.name}
                  </Badge>
                </PopTip>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <Grid container item direction="row" justifyContent="flex-end" alignItems="flex-end">
        {archiveItems && archiveItems.length > 0 && (
          <Grid container item xs={12}>
            <Grid item xs={12} onClick={toggleShowArchives} className={`${cls.menuItem} ${cls.archiveItems}`}>
              <i className="fal fa-archive" /> {showArchives ? "Hide" : "Show"} Archives
            </Grid>
          </Grid>
        )}
        <Grid container item xs={12}>
          <Grid item xs={12} onClick={newItem} className={`${cls.menuItem} ${cls.newItem}`}>
            <i className="fal fa-plus" /> {newItemText ? newItemText : "New Item"}
          </Grid>
        </Grid>
        {newItem2 && (
          <Grid container item xs={12}>
            <Grid item xs={12} onClick={newItem2} className={`${cls.menuItem} ${cls.newItem} ${cls.newItem2}`}>
              <i className="fal fa-plus" /> {newItem2Text}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default ManagedMenu;
