export default {
  darkBildBlue: "#004882",
  bildBlue: "#006dc3",
  antiochPurple: "#bd7683",

  darkBlue: "#2a5771",
  blue: "#3c7a9e",
  lightBlue: "#51a4d4",
  veryLightBlue: "#b7daef",
  progressBlue: "#51a4d4",

  darkerGreen: "#095609",
  darkGreen: "#4b9379",
  green: "#9DCB42",
  lightGreen: "#3ae43a",
  veryLightGreen: "#D9E9A0",
  extremelyLightGreen: "#f3fff3",

  darkerYellow: "#cccc00",
  darkYellow: "#d4d408",
  yellow: "#ffff00",
  lightYellow: "#ffff77",
  veryLightYellow: "#ffffc4",

  darkOrange: "#cc8400",
  orange: "#ffa500",
  lightOrange: "#ffb835",
  veryLightOrange: "#fbd58f",

  darkerRed: "#8e0202",
  darkRed: "#bd0202",
  red: "#ff0000",
  lightRed: "#ffb3b3",
  veryLightRed: "#feecec",

  darkerGray: "#858585",
  darkGray: "#a0a0a0",
  gray: "#bdbdbd",
  lightGray: "#d8d8d8",
  veryLightGray: "#ececec",
  bildGray: "#565656",

  darkerPurple: "#790d93",
  darkPurple: "#6f3879",
  purple: "#b65fc7",
  lightPurple: "#e378f7",
  veryLightPurple: "#e7b0f1",

  brown: "#a55d2a",

  pink: "#DB87D4",

  offWhite: "#f9f9f9",

  transparent: "transparent",
  white: "#FFFFFF",
  black: "#000000",

  avatarColors: [
    { backgroundColor: "#626fff", color: "black" },
    { backgroundColor: "#74ccff", color: "black" },
    { backgroundColor: "#2e8ca9", color: "white" },
    { backgroundColor: "#44cce2", color: "white" },
    { backgroundColor: "#381c90", color: "white" },
    { backgroundColor: "#5041d9", color: "white" },
    { backgroundColor: "#f06b56", color: "white" },
    { backgroundColor: "#f8b572", color: "white" },
    { backgroundColor: "#2db087", color: "white" },
    { backgroundColor: "#6cbf56", color: "black" }
  ],

  brt: "#2e3192",
  grt: "#5d57ff",
  prt: "#00a7b6",
  networkofnetworks: "#efbf47",
  network: "#8dc63f",
  localnetwork: "#fe7070",
  localorganization: "#5ed5ff",

  level1Group: "#4f9fdc",
  level2Group: "#30c186",
  level3Group: "#fdbf42",
  level4Group: "#ff5757",
};
