import React from "react";
import { colors, PopTip } from "bild-ui";
import { makeStyles, createStyles, useTheme } from "@mui/styles";
import { Grid, Badge } from "@mui/material";

const useStyles = makeStyles((theme: useTheme) =>
  createStyles({
    menu: {},
    menuItem: {
      padding: "0.75rem 1rem",
      "&:hover": { backgroundColor: colors.gray, cursor: "pointer" }
    },
    name: {
      overflow: "hidden",
      display: "block",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      "&> span": {
        top: "5px",
        right: "10px"
      }
    },
    active: {
      backgroundColor: colors.lightBlue,
      color: colors.white,
      fontWeight: "bold",
      "&:hover": { backgroundColor: colors.lightBlue, cursor: "pointer" }
    },
    archiveChannels: { backgroundColor: colors.darkerGray, color: colors.white, fontWeight: "bold", textAlign: "center", maxHeight: "4rem" },
    newChannel: { backgroundColor: colors.darkGray, color: colors.white, fontWeight: "bold", textAlign: "center", maxHeight: "4rem" },
    channels: {
      overflowY: "auto",
      [theme.breakpoints.down('md')]: {
        maxHeight: "calc(88vh - 6rem) !important"
      },
      [theme.breakpoints.up("sm")]: {
        maxHeight: "calc(100vh - 6rem)"
      }
    }
  })
);

function MessageMenu({ channels, favoriteChannels, archiveChannels, currentChannel, setCurrentChannel, newChannel, showArchives, toggleShowArchives }) {
  const cls = useStyles();
  const archiveBadge = channels.filter(x => archiveChannels.includes(x.id)).some(x => x.read === false);

  return (
    <Grid container direction="column" justifyContent="space-between" alignItems="stretch" className={cls.menu}>
      <Grid container item direction="row" justifyContent="flex-start" alignItems="flex-start" className={cls.channels}>
        <Grid container item xs={12}>
          {channels
          .filter(x => showArchives ? true : !archiveChannels.includes(x.id))
          .map((x,i) => {
            return (
              <Grid
                item
                xs={12}
                onClick={() => {
                  setCurrentChannel(x.id);
                }}
                key={i}
                className={`${cls.menuItem} ${currentChannel === x.id ? cls.active : ""}`}
              >
                <PopTip text={x.name.length > 19 ? x.name : null}>
                  <Badge color="secondary" variant="dot" invisible={x.read} className={cls.name}>
                    {archiveChannels && archiveChannels.length > 0 && archiveChannels.includes(x.id) && (
                      <><i className={`fas fa-archive fa-fw fa-xs`} /> &nbsp;</>
                    )}
                    {favoriteChannels && favoriteChannels.length > 0 && favoriteChannels.includes(x.id) && (
                      <><i className={`fas fa-star fa-fw fa-xs`} /> &nbsp;</>
                    )}
                    {x.name}
                  </Badge>
                </PopTip>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <Grid container item direction="row" justifyContent="flex-end" alignItems="flex-end">
        {archiveChannels && archiveChannels.length > 0 && (
          <Grid container item xs={12}>
            <Grid item xs={12} onClick={toggleShowArchives} className={`${cls.menuItem} ${cls.archiveChannels}`}>
              <Badge color="secondary" variant="dot" invisible={!archiveBadge} className={cls.name}>
                <i className="fal fa-archive" /> {showArchives ? "Hide" : "Show"} Archives
              </Badge>
            </Grid>
          </Grid>
        )}
        <Grid container item xs={12}>
          <Grid item xs={12} onClick={newChannel} className={`${cls.menuItem} ${cls.newChannel}`}>
            <i className="fal fa-plus" /> New Message
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default MessageMenu;
