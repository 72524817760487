import { ENUMS } from "bild-data";
import { colors } from "bild-ui";

export default function makeDecisions(userRoles, course, competency, expanded, credits, score, trainingAssessment, auditView) {
  // The id of the role which needs to make the next move in the assessment flow.
  const waitingOnRoleId = competency.proof.waitingOn.id;
  const statusCode = competency.competencyProof ? competency.competencyProof.statusCode : "null";

  // The roles of the user viewing the page.
  const callingUserRoles = userRoles || course.callingUserRoles || [];
  const hasRole = roleId => callingUserRoles.some(r => !!roleId && (r || {}).id === roleId);

  const assessmentFlowId = competency.assessmentFlowId;
  const readOnly = assessmentFlowId === ENUMS.ASSESSMENT.FLOWS.AS_STAFF.ID;
  const isStudent = waitingOnRoleId === ENUMS.ROLES.STUDENT.ID && hasRole(ENUMS.ROLES.STUDENT.ID);
  // AF role can be one of three options, (normal, trainee, or coach)
  const waitingOnAf = waitingOnRoleId === ENUMS.ROLES.ASSOCIATE_FACULTY.ID;
  const waitingOnAfCoach = statusCode.substring(0, 8) === "af_coach";
  const isAfTrainee = waitingOnAf && hasRole(ENUMS.ROLES.AF_TRAINEE.ID) && !waitingOnAfCoach;
  const isAfCoach = waitingOnAf && hasRole(ENUMS.ROLES.AF_COACH.ID) && waitingOnAfCoach && trainingAssessment;
  const isAfTraining = isAfTrainee || isAfCoach;
  const isAssociateFaculty = waitingOnAf && (hasRole(ENUMS.ROLES.ASSOCIATE_FACULTY.ID) || isAfTraining);
  const isAdmin = hasRole(ENUMS.ROLES.ADMIN.ID);
  const showTrainingHistory = hasRole(ENUMS.ROLES.AF_TRAINEE.ID) || hasRole(ENUMS.ROLES.AF_COACH.ID) || hasRole(ENUMS.ROLES.ADMIN.ID);
  const showFullTrainingHistory = hasRole(ENUMS.ROLES.AF_COACH.ID) || hasRole(ENUMS.ROLES.ADMIN.ID);

  // User can assess if they have the role that is being waited on OR if it is waiting on a training role that this user has
  const isActiveTraining = isAfTraining && (!trainingAssessment || (trainingAssessment && trainingAssessment.isActive));
  const waitingOnTrainingRole = isAssociateFaculty && isAfTraining && isActiveTraining;
  const canAssess = !auditView && (hasRole(waitingOnRoleId) || waitingOnTrainingRole);

  // Competency file info
  const files = competency.proof.files;
  const filesLength = files.length;
  const hasFiles = filesLength > 0;

  // readOnly is passed down as a prop, and competency should not expand for assessment
  // if no artifacts have been submitted.
  const expandable = (hasFiles && !readOnly) || auditView;
  // Text that shows up in the expand toggle.
  const expandToggleText = auditView ? "View Assessment" : (expandable && expanded ? (canAssess && hasFiles ? "Cancel" : "Close") : canAssess ? "Begin Assessment" : "View Assessment");

  // TODO standardize course data objects, they should not be manipulated after being retrieved from backend
  let courseColor = course.color ? course.color : course.courseGroupColor.webFrontendValue;

  // Color the toggle gray when no files have been submitted.
  const expandToggleColor =
    (expandToggleText === "View Assessment" && hasFiles) || expandToggleText === "Close"
      ? courseColor
      : expandToggleText === "Begin Assessment" && canAssess && hasFiles
      ? colors.green
      : colors.lightGray;

  // To update artifacts, user must be student, and must be permitted to modify files.
  const noProof = !competency.proof.id;
  const canUpdateArtifacts =
    hasRole(ENUMS.ROLES.STUDENT.ID) && ((assessmentFlowId !== ENUMS.ASSESSMENT.FLOWS.AS_STAFF.ID && noProof) || !!competency.proof.stCanModifyFiles)
    && !auditView;

  const creditsValue = parseFloat(credits);
  // Only validate if the user can assign credits, is giving a passing score, and the credit value is in the approved range
  const creditsInRange = creditsValue >= competency.proof.variableMinCredits && creditsValue <= competency.proof.variableMaxCredits;
  const creditsValid = !score.reviewPassed || creditsInRange;

  const assignsCredits = !!(competency.proof.canAssignCredits && canAssess);

  const showCredits =
    !!competency.proof.showCreditsGiven ||
    !!(competency.proof.creditsGiven && competency.proof.canAssignCredits && !canAssess) ||
    !!trainingAssessment;

  return {
    expandable,
    expandToggleText,
    expandToggleColor,
    readOnly,
    canUpdateArtifacts,
    hasFiles,
    canAssess,
    isStudent,
    showTrainingHistory,
    showFullTrainingHistory,
    waitingOnTrainingRole,
    isAfTrainee,
    isAfCoach,
    isAssociateFaculty,
    isAdmin,
    creditsInRange,
    creditsValid,
    assignsCredits,
    showCredits
  };
}
