import React, { useState, useEffect } from "react";
import { colors, BTypography, NormalButton, MembersChecklist } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Dialog, DialogContent, Grid, TextField } from "@mui/material";

const useStyles = makeStyles({
  form: { padding: "1rem" },
  name: { width: "100%" },
  rowItem: { paddingTop: "1rem" },
  users: { overflowY: "auto", minHeight: "15vh", maxHeight: "30vh", border: `1px solid ${colors.veryLightBlue}`, borderRadius: "4px" },
  button: { padding: "1rem" }
});

function UpdateChannelDialog({ open, closeDialog, user, channel, recipients, updateChannel }) {
  const cls = useStyles();
  const [name, setName] = useState(channel ? channel.name : "");
  const [members, setMembers] = useState(channel ? channel.members : []);
  const [users, setUsers] = useState(channel ? channel.members.map(x => x.id) : []);

  useEffect(() => {
    setName(channel ? channel.name : "");
    setMembers(channel ? channel.members : []);
    setUsers(channel ? channel.members.map(x => x.id) : []);
  }, [open]);

  function _updateChannel() {
    updateChannel(channel.id, name, users);
    closeDialog();
  }

  function changeUser(userId) {
    let index = users.indexOf(userId);
    if (index === -1) {
      setUsers([...users, userId]);
    } else {
      setUsers(users.filter(u => u !== userId));
    }
  }

  if (open) {
    return (
      <Dialog open={open} onClose={closeDialog}>
        <DialogContent>
          <Grid container justifyContent="center" alignItems="center" className={cls.form}>
            <Grid container item xs={12}>
              <label className={cls.name}>
                <Grid item xs={12}>
                  <BTypography variant="subtitle2">Group Name:</BTypography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={name}
                    variant="outlined"
                    onChange={e => {
                      setName(e.target.value);
                    }}
                    fullWidth
                  />
                </Grid>
              </label>
            </Grid>
            <Grid item xs={12} className={cls.rowItem}>
              <BTypography variant="subtitle2">Members:</BTypography>
            </Grid>
            <Grid item xs={12} className={cls.users}>
              <MembersChecklist filterable user={user} members={members} selectedMembers={users} updateMember={changeUser} />
            </Grid>
            <Grid item xs={12} className={cls.rowItem}>
              <BTypography variant="subtitle2">Add Members:</BTypography>
            </Grid>
            <Grid item xs={12} className={cls.users}>
              <MembersChecklist
                filterable
                user={user}
                members={recipients.filter(r => !members.map(x => x.id).includes(r.user.id))}
                selectedMembers={users}
                updateMember={changeUser}
              />
            </Grid>
            <Grid container item xs={12} className={cls.rowItem}>
              <Grid item xs={12}>
                <NormalButton variant="primary" fullWidth borderColor={colors.black} onClick={_updateChannel} className={cls.button}>
                  Update
                </NormalButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  } else {
    return null;
  }
}

export default UpdateChannelDialog;
