import React, { useState, useEffect } from "react";
import { colors, ManagedMenu, BTypography, NormalButton } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import GroupBlock from "./groupBlock.js";
import CreateUpdateGroupDialog from "./createUpdateGroupDialog.js";
import CreateUpdateCohortDialog from "./createUpdateCohortDialog.js";
import SelectNewGroupTypeDialog from "./selectNewGroupTypeDialog.js";

const useStyles = makeStyles({
  containerWrapper: { height: "85vh", maxHeight: "85vh" },
  header: { padding: "0.5rem 1rem" },
  menuWrapper: { backgroundColor: colors.lightGray },
  groupWrapper: { backgroundColor: colors.white },
  group: {}
});

function ManageGroup({ users, paths, groups, startGroupId, createGroup, createUpdateGroup, createUpdateCohort, favoriteGroups, archiveGroups, toggleFavoriteGroup, toggleArchiveGroup, onCloseDialog }) {
  const cls = useStyles();
  const [currentGroup, setCurrentGroup] = useState(startGroupId ? startGroupId : groups[0] ? groups[0].id : null);
  const [newDialog, setNewDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [newGroupDialog, setNewGroupDialog] = useState(false);
  const [newCohortDialog, setNewCohortDialog] = useState(false);
  const [addCohortDialog, setAddCohortDialog] = useState(false);
  const [showArchives, setShowArchives] = useState(false);

  useEffect(() => {
    if (startGroupId) {
      setCurrentGroup(startGroupId);
    }
  }, [startGroupId]);

  function toggleShowArchives() {
    setShowArchives(!showArchives);
  }

  return (
    <Grid container>
      <Grid container item xs={12} className={cls.header} justifyContent={"space-between"} alignItems={"center"}>
        <Grid item>
          <BTypography variant="h3">Sharing Groups</BTypography>
        </Grid>
        {onCloseDialog && (
          <Grid item>
            <NormalButton onClick={onCloseDialog} variant="secondary" color={colors.white} hoverColor={colors.lightGray} labelColor={colors.black} hoverLabelColor={colors.black}>
              <i className="fal fa-times fa-2x" />
            </NormalButton>
          </Grid>
        )}
      </Grid>
      <Grid container item xs={12} className={cls.containerWrapper} direction="row" justifyContent="flex-start" alignItems="stretch">
        <Grid container item lg={2} md={3} xs={3} justifyContent="flex-start" alignItems="stretch" className={cls.menuWrapper}>
          <ManagedMenu
            items={groups}
            currentItem={currentGroup}
            setCurrentItem={setCurrentGroup}
            newItem={() => {
              setNewDialog(true);
            }}
            newItemText="New Group/Cohort"
            favoriteItems={favoriteGroups}
            archiveItems={archiveGroups}
            showArchives={showArchives}
            toggleShowArchives={toggleShowArchives}
          />
        </Grid>
        <Grid container item lg={10} md={9} xs={9} justifyContent="flex-start" alignItems="flex-start" className={cls.groupWrapper}>
          <Grid container item xs={12} justifyContent="flex-start" alignItems="flex-start" className={cls.group}>
            {groups && groups.length > 0 && currentGroup && (
              <GroupBlock
                group={groups.filter(x => x.id === currentGroup)[0]}
                addCohort={() => {
                  setAddCohortDialog(true);
                }}
                editGroup={() => {
                  setEditDialog(true);
                }}
                favoriteGroups={favoriteGroups}
                archiveGroups={archiveGroups}
                toggleFavoriteGroup={toggleFavoriteGroup}
                toggleArchiveGroup={toggleArchiveGroup}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <SelectNewGroupTypeDialog
        open={newDialog}
        closeDialog={() => {
          setNewDialog(false);
        }}
        openNewGroupDialog={()=>{
          setNewDialog(false);
          setNewGroupDialog(true);
        }}
        openNewCohortDialog={()=>{
          setNewDialog(false);
          setNewCohortDialog(true);
        }}
      />
      <CreateUpdateGroupDialog
        open={newGroupDialog || editDialog}
        closeDialog={() => {
          setNewGroupDialog(false);
          setEditDialog(false);
        }}
        group={editDialog ? groups.filter(x => x.id === currentGroup)[0] : null}
        allUsers={users}
        allPaths={paths}
        channel={editDialog ? groups.filter(x => x.id === currentGroup)[0] : null}
        createUpdateGroup={createUpdateGroup}
      />
      <CreateUpdateCohortDialog
        open={newCohortDialog || addCohortDialog}
        closeDialog={() => {
          setNewCohortDialog(false);
          setAddCohortDialog(false);
        }}
        group={addCohortDialog ? groups.filter(x => x.id === currentGroup)[0] : null}
        allUsers={users}
        allPaths={paths}
        createUpdateCohort={createUpdateCohort}
      />
    </Grid>
  );
}

export default ManageGroup;
