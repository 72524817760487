import React, { useState, useEffect } from "react";
import { colors, BTypography, NormalButton, CountrySelect, FormAutocompleteField, UserAvatar, TabButtons, PopTip } from "bild-ui";
import { isValidEmail } from "bild-utils";
import { makeStyles } from "@mui/styles";
import { Grid, TextField } from "@mui/material";

const useStyles = makeStyles({
  labelWrapper: { color: colors.bildBlue, paddingTop: "0.75rem" },
  field: { width: "100%" },
  button: { margin: "1rem 0" },
  errors: { backgroundColor: colors.lightRed, marginBottom: "1rem" },
  error: { color: colors.red },
  errorWrapper: { color: colors.red, "& fieldset": { borderColor: colors.red } },
  accountType: { maxWidth: "35rem" },
  userFoundWrapper: { paddingRight: "2rem", },
  emailNote: {
    padding: "0.25rem 0.5rem 0.5rem 0.5rem",
    margin: "0.5rem 0",
    border: `1px solid ${colors.lightBlue}`,
    borderRadius: "4px",
    background: colors.veryLightBlue,
    fontStyle: "italic"
  },
  rightCol: { paddingLeft: "1rem" },
  submitWrapper: { padding: "1rem 0" },
  submit: { padding: "1rem 2rem", marginLeft: "1rem" },
  cancel: { padding: "0.75rem 1.5rem" }
});

function AddUser({ orgs, roles, createUpdateUser, defaultOrg, defaultId, defaultName, defaultEmail, checkEmail, onCancel }) {
  const cls = useStyles();
  const [state, setState] = useState({
    errors: [],
    name: "",
    offline: false,
    email: "",
    city: "",
    country: "",
    org: defaultOrg ? defaultOrg : null,
    role: null
  });
  const [email, setEmail] = useState(null);
  const [emailExists, setEmailExists] = useState(false);
  const [existingUser, setExistingUser] = useState({});

  useEffect(() => {
    setState({
      ...state,
      name: defaultName ? defaultName : "",
      offline: false,
      email: defaultEmail ? defaultEmail : "",
      city: defaultName ? defaultName : "",
      country: defaultName ? defaultName : "",
      org: defaultOrg ? defaultOrg : null
    });
  }, [defaultOrg, defaultName, defaultEmail]);

  function _checkEmail() {
    if (!defaultEmail && !state.offline) {
      if (email !== state.email) {
        if (isValidEmail(state.email)) {
          checkEmail(state.email, data => {
            const exists = data.users.length > 0;
            setEmailExists(exists);
            setExistingUser(exists ? data.users[0] : {});
            setState({
              ...state,
              name: data.users[0].name,
              city: "Ames",
              country: "USA",
            });
          });
        } else {
          setEmailExists(false);
          setExistingUser({});
        }
        setEmail(state.email);
      }
    }
  }

  function _createUser() {
    // First check everything is in order, and then createUser
    let newError = [];

    // Check form for mistakes
    if (!state.offline && (state.email === "" || state.email === null || !isValidEmail(state.email))) {
      newError.push({ pos: 2, val: "Need a valid email address." });
    }
    if (!emailExists && state.name === "" || state.name === null) {
      newError.push({ pos: 3, val: "Name needs to be filled in." });
    }
    if (!emailExists && state.country === "" || state.country === null) {
      newError.push({ pos: 4, val: "Need a valid country." });
    }
    if (!emailExists && state.city === "" || state.city === null) {
      newError.push({ pos: 5, val: "Need a valid city." });
    }
    if (state.org === "" || state.org === null) {
      newError.push({ pos: 6, val: "Organization needs to be selected." });
    }
    if (state.role === "" || state.role === null) {
      newError.push({ pos: 7, val: "Role needs to be selected." });
    }

    // Check if the basics are valid
    if (newError.length <= 0) {
      // Create/Update the user
      createUpdateUser(defaultId, state.name, state.offline, state.email, state.city, state.country, state.org, state.role);
      _reset();
    } else {
      setState({ ...state, errors: newError });
    }
  }

  function _toggleAccountType(val) {
    setEmailExists(false);
    setExistingUser({});
    setState({
      ...state,
      errors: [],
      offline: val,
      email: "",
    });
  }

  function _reset() {
    setEmailExists(false);
    setExistingUser({});
    setState({
      errors: [],
      name: defaultName ? defaultName : "",
      offline: false,
      email: defaultEmail ? defaultEmail : "",
      city: defaultEmail ? defaultEmail : "",
      country: defaultEmail ? defaultEmail : "",
      org: null,
      role: null
    });
  }

  function _cancel() {
    _reset();
    if (typeof onCancel === "function") onCancel();
  }

  function textField(pos, label, value, setValue, classes, disabled) {
    let child = (
      <TextField
        variant="outlined"
        placeholder={label}
        value={value}
        onChange={e => {
          if (!disabled) {
            setValue(e.target.value);
          }
        }}
        className={`${cls.field} ${classes}`}
        disabled={disabled}
        onBlur={_checkEmail}
        autoFocus={pos === 2 ? true : false}
      />
    );
    return newField(pos, label, child);
  }

  function selectField(pos, label, value, items, setValue, classes, disabled) {
    let child = <FormAutocompleteField value={value} setValue={setValue} items={items} />;
    return newField(pos, label, child);
  }

  function countrySelectField(pos, label, value, setValue, classes, disabled) {
    let child = <CountrySelect value={value} setValue={setValue} className={`${cls.field} ${classes}`} disabled={disabled} />;
    return newField(pos, label, child);
  }

  function tabsField(pos, label, tab1, tab2, value, setValue, classes, disabled) {
    let child = (
      <Grid container justifyContent="center" alignItems="center">
        <Grid item className={`${cls.field} ${classes}`}>
          <TabButtons tab1Text={tab1} tab2text={tab2} value={value} setValue={setValue} color={value ? colors.darkRed : colors.darkBlue} disabled={disabled} />
        </Grid>
      </Grid>
    );
    return newField(pos, label, child);
  }

  function newField(pos, label, child) {
    let err = state.errors.some(x => x.pos === pos);
    return (
      <Grid container item sm={pos > 3 ? 6 : 12} xs={12} className={pos > 3 && pos % 2 === 1 ? cls.rightCol : ""}>
        <Grid item xs={12} className={`${cls.labelWrapper} ${err ? cls.errorWrapper : ""}`}>
          {label}
        </Grid>
        <Grid item xs={12} className={`${cls.fieldWrapper} ${err ? cls.errorWrapper : ""}`}>
          {child}
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container justifyContent="center" alignItems="center">
      {state.errors.length > 0 && (
        <Grid item xs={12} className={cls.errors}>
          <ul>
            {state.errors.map((x, i) => (
              <li key={i} className={cls.error}>
                {x.val}
              </li>
            ))}
          </ul>
        </Grid>
      )}
      <Grid item xs={12} className={cls.title}>
        <BTypography variant="h3">
          {emailExists ? "Connect " : "Add "} {defaultName ? `Role: ${defaultName}` : "User"}
        </BTypography>
      </Grid>
      {!defaultName && (
        <>
          {tabsField(
            1,
            "",
            <PopTip text={<>A person that can log in to BILD Cloud and perform all normal actions.</>}>Regular <i className="fas fa-info-circle" /></PopTip>,
            <PopTip text={<>A person that will <u>not</u> log in to BILD Cloud but can still be assigned programs and receive certificates.</>}>Offline-Only <i className="fas fa-info-circle" /></PopTip>,
            !state.offline ? 0 : 1,
            x => {
              _toggleAccountType(x === 1 ? true : false);
            },
            cls.accountType,
            false
          )}
          {!state.offline && textField(
            2,
            "Email",
            state.email,
            x => {
              setState({ ...state, email: x });
            },
            `${cls.email} ${emailExists ? cls.emailExists : ""}`,
            defaultEmail ? true : false
          )}
          {emailExists && (
            <Grid item xs={12}>
              <Grid container justifyContent={"flex-start"} alignItems={"center"} className={cls.emailNote}>
                <Grid item className={cls.userFoundWrapper}>
                  <UserAvatar size={35} src={existingUser.avatarURL} name={existingUser.name} nameVariant="subtitle1" badgeSrc={existingUser.badgeURL}/>
                </Grid>
                <Grid item xs>
                  <BTypography variant="body1">
                    The email you entered is associated with a BILD Cloud user, that existing account will be connected to your organization.
                  </BTypography>
                </Grid>
              </Grid>
            </Grid>
          )}
          {!emailExists && textField(
            3,
            "Full Name",
            state.name,
            x => {
              setState({ ...state, name: x });
            },
            cls.name,
            defaultName ? true : false
          )}
          {!emailExists && countrySelectField(
            4,
            "Country",
            state.country,
            x => {
              setState({ ...state, country: x });
            },
            cls.country,
            defaultName ? true : false
          )}
          {!emailExists && textField(
            5,
            "City",
            state.city,
            x => {
              setState({ ...state, city: x });
            },
            cls.city,
            defaultName ? true : false
          )}
        </>
      )}
      {selectField(
        6,
        "Organization",
        state.org,
        orgs,
        x => {
          setState({ ...state, org: x });
        },
        cls.localOrg,
        false
      )}
      {selectField(
        7,
        "Role",
        state.role,
        roles,
        x => {
          setState({ ...state, role: x });
        },
        cls.role,
        false
      )}
      <Grid container item xs={12} className={cls.submitWrapper} justifyContent={"flex-end"} alignItems={"center"}>
        <Grid item>
          <NormalButton
            onClick={_cancel}
            variant={"secondary"}
            color={colors.transparent}
            borderColor={colors.darkGray}
            labelColor={colors.darkGray}
            hoverColor={colors.lightGray}
            hoverLabelColor={colors.black}
            className={cls.cancel}
          >
            Cancel
          </NormalButton>
        </Grid>
        <Grid item>
          <NormalButton
            onClick={_createUser}
            variant={"primary"}
            color={colors.bildBlue}
            labelColor={colors.white}
            hoverColor={colors.darkBildBlue}
            className={cls.submit}
          >
            Create
          </NormalButton>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AddUser;
