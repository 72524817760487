import React, { useState, useEffect } from "react";
import { equipReviewData, userData } from "bild-data";
import { access, setPageTitle } from "bild-utils";
import { EquipWrapper, LoadingScreen } from "bild-ui";
import ReviewSharedPaths from "./reviewSharedPaths.js";
import { useParams } from "react-router-dom";

function SharedPathsLoader({}) {
  const { group_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [groups, setGroups] = useState([]);
  const [groupId, setGroupId] = useState(null);
  const [currentGroup, setCurrentGroup] = useState(null);
  const userOverview = userData.getOverview();
  const [userPreferences, setUserPreferences] = useState(JSON.parse(userOverview.userPreferences));
  const [favoriteGroups, setFavoriteGroups] = useState(access(userPreferences, "sharingGroups.favoriteGroups", []));
  const [archiveGroups, setArchiveGroups] = useState(access(userPreferences, "sharingGroups.archiveGroups", []));

  useEffect(() => {
    function _setData(d) {
      setPageTitle("Shared Programs");
      setGroups(d.sharingGroups);
      setLoading(false);
      if (group_id) {
        setGroupId(Number(group_id));
      }
    }

    equipReviewData.loadShared(_setData, e => {
      setError(e.response.data.error);
    });
  }, []);

  useEffect(() => {
    if (groupId) {
      setCurrentGroup(groups.filter(g => g.id === groupId)[0]);
    } else {
      setCurrentGroup(null);
    }
  }, [groupId]);


  if (loading || error) return <LoadingScreen error={error} />;
  return (
    <EquipWrapper>
      <ReviewSharedPaths groups={groups} groupId={groupId} currentGroup={currentGroup} favoriteGroups={favoriteGroups} archiveGroups={archiveGroups} />
    </EquipWrapper>    
  );
}

export default SharedPathsLoader;
