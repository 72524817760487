import React, { useRef, useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { colors, Blink, UserAvatar, Chip, NormalButton, PopTip } from "bild-ui";
import { makeStyles, createStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { Message, MessageEditor } from "modules";

const useStyles = makeStyles((theme) =>
  createStyles({
    containerWrapper: { paddingBottom: "1rem" },
    topPlaceholder: { textAlign: "center", color: colors.gray },
    loadingMessages: { padding: "5rem", textAlign: "center", fontSize: "2rem", color: colors.bildBlue },
    messagesBlock: {
      overflow: "auto",
      [theme.breakpoints.down('md')]: {
        minHeight: "calc(94vh - 20rem)",
        maxHeight: "calc(94vh - 18rem) !important"
      },
      [theme.breakpoints.up("sm")]: {
        minHeight: "calc(100vh - 20rem)",
        maxHeight: "calc(100vh - 18rem)"
      }
    },
    message: { padding: "0.25rem 1rem", wordBreak: "break-all" },
    anchor: { borderTop: `1px solid ${colors.lightGray}`, padding: "0.5rem 0" },
    anchorText: { paddingRight: "0.5rem", color: colors.darkGray },
    anchorLink: { color: colors.darkGray, textDecoration: "underline" },
    name: { paddingRight: "1rem" },
    content: {
      padding: "0.75rem 0.75rem 0.25rem 0.75rem",
      borderRadius: "12px",
      position: "relative",
      "&:after": {
        content: "''",
        position: "absolute",
        bottom: "0",
        width: "0",
        height: "0",
        border: "21px solid transparent",
        borderBottom: "0",
      }
    },
    userContent: {
      backgroundColor: colors.veryLightBlue,
      "&:after": {
        right: "0",
        borderLeftColor: colors.veryLightBlue,
        borderRight: "0",
        marginRight: "-10px"
      }
    },
    otherContent: {
      backgroundColor: colors.veryLightGray,
      "&:after": {
        left: "0",
        borderRightColor: colors.veryLightGray,
        borderLeft: "0",
        marginLeft: "-10px",
      }
    },
    userIcon: { fontSize: "0.65rem", fontWeight: "100", color: colors.black, padding: "0.5rem 0" },
    avatar: { marginLeft: "0px !important" },
    username: { textAlign: "right" },
    editor: { padding: "1rem" },
    members: { padding: "0 0 0 1rem", overflowY: "auto", maxHeight: "9vh" },
    member: { padding: "0.25rem" },
    chip: { "&:hover": { backgroundColor: colors.veryLightBlue, cursor: "pointer !important", borderColor: colors.lightBlue } },
    sendingMessage: {
      backgroundColor: colors.veryLightGray,
      color: colors.darkBildBlue,
      "&:after": {
        borderLeftColor: `${colors.veryLightGray} !important`
      }
    },
    bottomRef: { paddingTop: "1.5rem" },
    userPrefButtonWrapper: { padding: "0.25rem 0" },
  })
);

function MessageBlock({ user, channel, favoriteChannels, archiveChannels, messages, openChannel, createMessage, updateChannel, isLoadingMessages, isMessageSending, toggleFavoriteChannel, toggleArchiveChannel }) {
  const cls = useStyles();
  const topRef = useRef(null);
  const bottomRef = useRef(null);
  const [size, setSize] = useState(0);
  let lastUserId;
  const showUpdate = channel.type.id === 2 && channel.members.filter(x => x.id === user.id)[0].memberType.id === 1;

  useEffect(() => {
    if (size !== messages.length) {
      bottomRef.current.scrollIntoView();
      setSize(messages.length);
    }
  }, [messages]);

  useEffect(() => {
    if (isMessageSending) {
      bottomRef.current.scrollIntoView();
    }
  }, [isMessageSending]);

  function getAnchorLines(user, anchors) {
    let isPathItem = anchors[0].userPathItemId ? true : false;
    let isCourse = anchors[0].asCourseId ? true : false;
    let isCompetencyProof = anchors[0].asCompetencyProofId ? true : false;

    let dst;
    if (isPathItem) {
      dst = "equip-user-path-item";
    } else if (isCourse) {
      dst = "course";
    }

    return (
      <Grid container item xs={12} className={cls.anchor} justifyContent="center" alignItems="center">
        <Grid item className={cls.anchorText}>
          {user.name} sent this from
        </Grid>
        <Grid
          item
          component={Blink}
          dst={dst}
          user_path_item_id={isPathItem ? anchors[0].userPathItemId : null}
          course_id={isCourse ? anchors[0].asCourseId : null}
          competency_proof_id={isCompetencyProof ? anchors[0].asCompetencyProofId : null}
          enrollment_id={isCourse && !isCompetencyProof ? anchors[0].asEnrollmentId : null}
          className={cls.anchorLink}
        >
          {ReactHtmlParser(anchors[0].name)}
        </Grid>
      </Grid>
    );
  }

  function anchorLine(user, name, dst, id) {
    // The dst and id need to be able to handle "equip-user-path-item" and "equip-user-learning-item"
    return (
      <Grid container item xs={12} className={cls.anchor} justifyContent="center" alignItems="center">
        <Grid item className={cls.anchorText}>
          {user.name} sent this from
        </Grid>
        <Grid item component={Blink} dst={dst} user_learning_item_id={id} user_path_item_id={id} className={cls.anchorLink}>
          {name}
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container direction="column" justifyContent="space-between" alignItems="stretch" className={cls.containerWrapper}>
      <Grid container item direction="row" justifyContent="flex-start" alignItems="flex-start" className={cls.messagesBlock}>
        <Grid container item xs={12}>
          <Grid item xs={12} ref={topRef} className={cls.topPlaceholder}>
            -This is the start of your conversation-
          </Grid>
          {isLoadingMessages && messages.length === 0 && (
            <Grid item xs={12} className={cls.loadingMessages}>
              <i className="fas fa-spinner-third fa-spin fa-2x" />
            </Grid>
          )}
          {messages.map(m => {
            let isUser = m.user.id === user.id;
            let showName = m.user.id !== lastUserId;
            let showAnchor = m.anchors.length > 0;
            lastUserId = m.user.id;
            return (
              <Grid container item xs={12} justifyContent={isUser ? "flex-end" : "flex-start"} alignItems="center" key={m.id} className={cls.message}>
                {showAnchor && getAnchorLines(m.user, m.anchors)}
                {showName && (
                  <Grid
                    container
                    item
                    xs={12}
                    className={`${isUser ? cls.username : ""}`}
                    justifyContent={isUser ? "flex-end" : "flex-start"}
                    alignItems="center"
                  >
                    <Grid item className={cls.userIcon}>
                      <UserAvatar size={30} src={m.user.avatarURL} name={m.user.name} badgeSrc={m.user.badgeURL} srcSize="small" />
                    </Grid>
                  </Grid>
                )}
                <PopTip text={`Sent on ${m.createdAt}`} delay={1000} arrowPlacement="left">
                  <Grid item className={`${cls.content} ${isUser ? cls.userContent : cls.otherContent}`}>
                    <Message value={JSON.parse(m.content)} />
                  </Grid>
                </PopTip>
              </Grid>
            );
          })}
          {isMessageSending && (
            <Grid container item xs={12} justifyContent="flex-end" alignItems="center" className={cls.message}>
              <Grid item className={`${cls.content} ${cls.userContent} ${cls.sendingMessage}`}>
                <i className="fas fa-spinner-third fa-spin fa-2x" />
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} ref={bottomRef} className={cls.bottomRef}>
            &nbsp;
          </Grid>
        </Grid>
      </Grid>
      <Grid container item direction="row" justifyContent="flex-end" alignItems="flex-end">
        <Grid container item xs={12}>
          <Grid container item justifyContent="space-between" alignItems="center">
            <Grid item xs={12} className={cls.editor}>
              <MessageEditor onSend={createMessage} />
            </Grid>
            <Grid container item sm={9} xs={12} className={cls.members}>
              {channel.members.filter(x => x.id !== user.id).map(m => (
                <Grid
                  item
                  className={cls.member}
                  key={m.id}
                >
                  <Chip member={m} onClick={()=>{ openChannel([m.id]) }}/>
                </Grid>
              ))}
            </Grid>
            <Grid container item sm={3} xs={12} justifyContent="space-around" alignItems="center">
              {showUpdate && (
                <Grid item className={cls.userPrefButtonWrapper}>
                  <NormalButton variant="primary" onClick={updateChannel} borderColor={colors.black} hoverColor={colors.lightGray} fullWidth>
                    <i className="fal fa-cog fa-fw" />
                  </NormalButton>
                </Grid>
              )}
              <Grid item className={cls.userPrefButtonWrapper}>
                <PopTip text={`${favoriteChannels.includes(channel.id) ? "Remove from " : "Add to "}` + "my Favorites"}>
                  <NormalButton variant="primary" onClick={()=>{toggleFavoriteChannel(channel.id)}} borderColor={colors.black} hoverColor={colors.lightGray}>
                    <i className={`${favoriteChannels.includes(channel.id) ? "fas" : "fal"} fa-star`} />
                  </NormalButton>
                </PopTip>
              </Grid>
              <Grid item className={cls.userPrefButtonWrapper}>
                <PopTip text={`${archiveChannels.includes(channel.id) ? "Remove from " : "Add to "}` + "my Archive"}>
                  <NormalButton variant="primary" onClick={()=>{toggleArchiveChannel(channel.id)}} borderColor={colors.black} hoverColor={colors.lightGray}>
                    <i className={`${archiveChannels.includes(channel.id) ? "fas" : "fal"} fa-archive`} />
                  </NormalButton>
                </PopTip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default MessageBlock;
