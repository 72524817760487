import React from "react";
import { colors, Blink, BTypography, MembersList, NormalButton, PopTip } from "bild-ui";
import { makeStyles, createStyles, useTheme } from "@mui/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles((theme: useTheme) =>
  createStyles({
    containerWrapper: { padding: "0 0.5rem 0 1rem", maxHeight: "85vh", overflow: "scroll", },
    title: { padding: "0 0.5rem 0.5rem 0.5rem" },
    subtitle: { padding: "0 0.5rem 0.5rem 0.5rem" },
    membersWrapper: { padding: "0 0.5rem 0.5rem 0.5rem" },
    members: {
      overflowY: "auto",
      minHeight: "10vh",
      maxHeight: "30vh",
      padding: "0 0.5rem",
      border: `1px solid ${colors.veryLightBlue}`,
      borderRadius: "4px"
    },
    buttonWrapper: { paddingRight: "0.5rem" },
    userPrefButtonWrapper: { paddingLeft: "0.5rem" }
  })
);

function GroupBlock({ user, group, addCohort, editGroup, favoriteGroups, archiveGroups, toggleFavoriteGroup, toggleArchiveGroup }) {
  const cls = useStyles();

  return (
    <Grid container direction="column" justifyContent="space-between" alignItems="stretch" className={cls.containerWrapper}>
      <Grid container item direction="row" justifyContent="flex-end" alignItems="flex-end">
        <Grid container item xs={12}>
          <Grid container item justifyContent="space-between" alignItems="flex-start">
            <Grid container item xs={12} justifyContent="space-between" alignItems="center">
              <Grid item xs className={cls.title}>
                <BTypography variant="h4">{group.name}</BTypography>
              </Grid>
              <Grid item className={cls.buttonWrapper}>
                <NormalButton
                  component={Blink}
                  dst="equip-review-shared-paths"
                  group_id={group.id}
                  variant="secondary"
                  color={colors.white}
                  labelColor={colors.bildBlue}
                  borderColor={colors.bildBlue}
                  hoverColor={colors.bildBlue}
                >
                  View Shared Programs
                </NormalButton>
              </Grid>
              <Grid item className={cls.buttonWrapper}>
                <NormalButton onClick={addCohort} variant="simple" color={colors.bildBlue} labelColor={colors.white}>
                  Add Cohort to Group
                </NormalButton>
              </Grid>
            </Grid>
            <Grid container item xs={12} className={cls.subtitle}>
              <BTypography variant="h6">Programs:</BTypography>
            </Grid>
            <Grid container item xs={12} className={cls.membersWrapper}>
              <Grid item xs={12} className={cls.members}>
                <MembersList members={group.paths.sort((a, b) => a.name.localeCompare(b.name))} icon={"fas fa-badge-check"} />
              </Grid>
            </Grid>
            <Grid container item xs={12} className={cls.subtitle}>
              <BTypography variant="h6">People:</BTypography>
            </Grid>
            <Grid container item xs={12} className={cls.membersWrapper}>
              <Grid item xs={12} className={cls.members}>
                <MembersList members={group.users.sort((a, b) => a.name.localeCompare(b.name))} />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} justifyContent={"flex-end"} alignItems={"center"} className={cls.membersWrapper}>
            <Grid item className={cls.userPrefButtonWrapper}>
              <NormalButton variant="primary" onClick={editGroup} borderColor={colors.black} hoverColor={colors.lightGray} fullWidth>
                <i className="fal fa-cog fa-fw" />
              </NormalButton>
            </Grid>
            <Grid item className={cls.userPrefButtonWrapper}>
              <PopTip text={`${favoriteGroups.includes(group.id) ? "Remove from " : "Add to "}` + "my Favorites"}>
                <NormalButton variant="primary" onClick={()=>{toggleFavoriteGroup(group.id)}} borderColor={colors.black} hoverColor={colors.lightGray}>
                  <i className={`${favoriteGroups.includes(group.id) ? "fas" : "fal"} fa-star`} />
                </NormalButton>
              </PopTip>
            </Grid>
            <Grid item className={cls.userPrefButtonWrapper}>
              <PopTip text={`${archiveGroups.includes(group.id) ? "Remove from " : "Add to "}` + "my Archive"}>
                <NormalButton variant="primary" onClick={()=>{toggleArchiveGroup(group.id)}} borderColor={colors.black} hoverColor={colors.lightGray}>
                  <i className={`${archiveGroups.includes(group.id) ? "fas" : "fal"} fa-archive`} />
                </NormalButton>
              </PopTip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default GroupBlock;
