import React, { useEffect, useState } from "react";
import { colors, Blink, BTypography, PaginationList, SortableList, UserAvatar, NormalButton, Chip, PopTip, FilterTextField } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import ReactHtmlParser from "react-html-parser";
import { ManageGroupsDialogLoader } from "modules";
import { checkManager } from "bild-data/user";

const useStyles = makeStyles({
  wrapper: {
    padding: "0 0 0rem 0",
  },
  header: {
    padding: "0 0 0rem 0"
  },
  subHeader: {
    padding: "0.5rem 1rem",
    marginBottom: "0rem",
    background: colors.white,
  },
  backButton: {
    padding: "1rem",
  },
  sectionTitle: { padding: "1rem 0 0.5rem 0", },
  groupName: {
    padding: "0 1rem"
  },
  groupWrapper: {
    padding: "1rem",
    background: colors.white,
  },
  groups: {
    paddingBottom: "0rem"
  },
  membersWrapper: {
    maxHeight: "40vh",
    overflow: "auto"
  },
  userChips: {
    maxHeight: "4.5rem",
    overflow: "auto"
  },
  pathWrapper: {
    minHeight: "5rem",
    padding: "0.5rem"
  },
  path: {
    border: `0.1em solid transparent`,
    borderRadius: "4px",
    padding: `1rem`,
    backgroundColor: colors.white
  },
  pathIcon: {
    lineHeight: "2rem",
    paddingRight: "0.5rem",
  },
  pathTitle: {
    lineHeight: "2rem",
    fontWeight: "bold",
    marginBottom: "0rem",
    "& sup": {
      lineHeight: "1rem"
    },
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineClamp: "3",
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "line-clamp": 3,
    "-webkit-box-orient": "vertical",
  },
  pathSubtitle: { },
  user: {
    padding: "0.2rem 0"
  },
  topItem: { padding: "0.25rem 0.25rem 0.25rem 0" },
  archiveGroups: { backgroundColor: colors.darkerGray, color: colors.white, fontWeight: "bold", textAlign: "center", maxHeight: "4rem" },
});

function ReviewSharedPaths({ groups, groupId, currentGroup, favoriteGroups, archiveGroups }) {
  const cls = useStyles();
  const [currentGroups, setCurrentGroups] = useState([]);
  const [filter, setFilter] = useState("");
  const [showArchives, setShowArchives] = useState(false);
  const [groupDialog, setGroupDialog] = useState(false);
  const isStaff = checkManager();

  useEffect(() => {
    setCurrentGroups(groups.filter(g => {
      if (filter.length > 0) {
        return (
          g.name.toLowerCase().includes(filter.toLowerCase())
        );
      } else {
        return 1;
      }
    }))
  }, [groups, filter]);

  function toggleShowArchives() {
    setShowArchives(!showArchives);
  }

  return (
    <Grid container className={cls.wrapper}>
      <Grid container item xs={12} className={cls.groups}>
        {!groupId && groups && (
          <Grid container item xs={12} className={cls.header} justifyContent="space-between" alignItems="flex-start">
            <Grid container item xs={12} className={cls.subHeader} justifyContent="space-between" alignItems="center">
              <Grid item xs={4}>
                <BTypography variant="h4">Sharing Groups</BTypography>
              </Grid>
              <Grid container item xs={8} justifyContent={"flex-end"} alignItems={"center"}>
                {archiveGroups && archiveGroups.length > 0 && (
                  <Grid item className={cls.topItem}>
                    <NormalButton
                      onClick={toggleShowArchives}
                      variant={"secondary"}
                      color={colors.white}
                      labelColor={colors.bildBlue}
                      borderColor={colors.bildBlue}
                      hoverColor={colors.bildBlue}
                    >
                      <i className="fal fa-archive" /> &nbsp; {showArchives ? "Hide" : "Show"} Archives
                    </NormalButton>
                  </Grid>
                )}
                {isStaff && (
                  <Grid item className={cls.topItem}>
                    <NormalButton
                      onClick={() => {
                        setGroupDialog(true);
                      }}
                      variant="primary"
                      color={colors.bildBlue}
                      hoverColor={colors.darkBildBlue}
                      labelColor={colors.white}
                      className={cls.detailButton}
                    >
                      Manage Groups
                    </NormalButton>
                  </Grid>
                )}
                <Grid item className={cls.topItem}>
                  <NormalButton
                    component={Blink}
                    dst={"equip-review-shared"}
                    variant={"primary"}
                    color={colors.bildBlue}
                    labelColor={colors.white}
                  >
                    Sharing Group Progress
                  </NormalButton>
                </Grid>
                <Grid item>
                  <FilterTextField
                    value={filter}
                    placeholder="Search"
                    onChange={e => setFilter(e.target.value)}
                    onClear={() => {
                      setFilter("");
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={cls.body}>
              <SortableList
                headers={[`★`, "Group"]}
                sortableColumns={[0,1]}
                defaultSortCol={1}
                defaultSortDir={2}
                perPage={7}
                itemType={"Groups"}
                fixedHeight={"60vh"}
                spacing={[1,11]}
                items={
                  currentGroups
                  .filter(x => showArchives ? true : !archiveGroups.includes(x.id))
                  .map((x, i) => [
                    [<Grid container sortval={archiveGroups && archiveGroups.includes(x.id) ? "1" : (favoriteGroups && favoriteGroups.includes(x.id) ? "3" : "2")} justifyContent={"center"} alignItems={"center"}>
                      <Grid item>
                        {archiveGroups && archiveGroups.includes(x.id) && (
                          <><i className={`fas fa-archive fa-fw fa-xs`} /> &nbsp;</>
                        )}
                        {favoriteGroups && favoriteGroups.includes(x.id) && (
                          <><i className={`fas fa-star fa-fw fa-xs`} /> &nbsp;</>
                        )}
                      </Grid>
                    </Grid>
                    ],
                    [<Grid container sortval={x.name} key={0}>
                      <Grid item xs={4}>
                        <BTypography variant="subtitle2">{x.name}</BTypography>
                      </Grid>
                      <Grid container item sm={7} xs={5} justifyContent={"flex-start"} alignItems={"center"} className={cls.userChips}>
                        {x.users.map((y,j) => { return (
                          <Grid item key={j}>
                            <Chip member={y} hideName size={25}/>
                          </Grid>
                        ); })}
                      </Grid>
                      <Grid container item sm={1} xs={3} justifyContent={"flex-end"} alignItems={"center"}>
                        <Grid item>
                          <NormalButton component={Blink} dst="equip-review-shared-paths" group_id={x.id} variant={"simple"} color={colors.bildBlue} labelColor={colors.white}>
                            View
                          </NormalButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    ]
                  ]
                )}
                emptyMessage={
                  <BTypography variant="h5">
                    No Sharing Groups
                  </BTypography>
                }
              />
            </Grid>
          </Grid>
        )}
        {groupId && currentGroup && (
          <Grid container item xs={12} className={cls.groupWrapper}>
            <Grid container item xs={12} justifyContent={"flex-start"} alignItems={"center"} className={cls.header}>
              <Grid item>
                <NormalButton component={Blink} dst="equip-review-shared-paths" variant={"simple"} color={colors.bildBlue} labelColor={colors.white} className={cls.backButton}>
                  <i className="fas fa-arrow-left" />
                </NormalButton>
              </Grid>
              <Grid item className={cls.groupName}>
                <BTypography variant="h4">{currentGroup.name}</BTypography>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <BTypography variant="h6" className={cls.sectionTitle}>Members:</BTypography>
              </Grid>
              <Grid container item xs={12} className={cls.membersWrapper}>
                {currentGroup.users.sort((a,b) => a.name.localeCompare(b.name)).map((x, i) => {
                  return (
                    <Grid item className={cls.user} key={i}>
                      <Chip member={x} />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <BTypography variant="h6" className={cls.sectionTitle}>Programs:</BTypography>
              </Grid>
              {currentGroup.paths.map((x, i) => {
                let color = x.programColor.webFrontendValue;
                let path = x;
                return (
                  <Grid container item sm={6} xs={12} className={cls.pathWrapper} key={i}>
                    <Grid
                      container
                      item
                      xs={12}
                      className={cls.path}
                      component={Blink}
                      dst="equip-review-shared-path"
                      path_id={x.id}
                      group_id={groupId}
                      style={{ borderColor: color }}
                      justifyContent="space-between"
                      alignItems="stretch"
                    >
                      <Grid container item xs={12} justifyContent={"center"} alignItems={"center"}>
                        <Grid container item xs>
                          <Grid item style={{ color: color }}>
                            <i className={`fal fa-badge-check ${cls.pathIcon}`} />
                          </Grid>
                          <Grid container item xs>
                            <Grid item xs={12}>
                              <PopTip text={path.title} delay={1000}>
                                <BTypography variant="h6" className={cls.pathTitle} style={{ color: color }}>
                                  {ReactHtmlParser(path.name)}
                                </BTypography>
                              </PopTip>
                            </Grid>
                            {path.subtitle && (
                              <Grid item xs={12}>
                                <BTypography variant="subitle2" className={cls.pathSubtitle} style={{ color: color }}>
                                  {ReactHtmlParser(path.subtitle)}
                                </BTypography>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                        <Grid item>
                          <BTypography variant="h6" className={cls.pathArrow} style={{ color: color }}>
                            <i className="far fa-chevron-right" />
                          </BTypography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        )}
      </Grid>
      <ManageGroupsDialogLoader
        open={groupDialog}
        onClose={() => {
          setGroupDialog(false);
        }}
      />
    </Grid>
  );
}

export default ReviewSharedPaths;
