import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Dialog } from "@mui/material";
import { colors, Blink, NormalButton, BTypography, TreeDiagram, SortableList, FormTextField } from "bild-ui";
import { getEnumByKeyValue } from "bild-utils";
import { ENUMS } from "bild-data";

const useStyles = makeStyles({
  submitButton: { padding: "0.5rem" },
  confirmDialog: { padding: "1rem 2rem" },
  confirmButton: { margin: "0.5rem 1rem", padding: "1rem 2rem" },
  title: { padding: "2rem 0", textAlign: "center" }
});

export default function CompetencyProofStatusConfirmDialog({ open, onClose, text, onSubmit }) {
  const cls = useStyles();
  const status = text ? getEnumByKeyValue(ENUMS.ASSESSMENT.STATUSES, "STATUS", text, "DESCRIPTION") : "";
  
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={"sm"}
    >
      <Grid container item xs={12} direction="column" className={cls.confirmDialog} justifyContent="center" alignItems="center">
        <Grid item className={cls.title}>
          <BTypography variant="h6">
            <i className={`fal fa-fast-${text === "complete" ? "forward" : "backward"}`} />
            &nbsp; Change Competency Proof status to <b>{status}</b>?</BTypography>
          <br/>
          <BTypography variant="body2"><i>This will only change the status, complete state, and updated_at fields.</i></BTypography>
        </Grid>
        <Grid container item xs direction={"row"} justifyContent="center" alignItems="center">
          <Grid item>
            <NormalButton
              component={Grid}
              item
              variant="secondary"
              color={colors.white}
              labelColor={colors.gray}
              borderColor={colors.gray}
              hoverColor={colors.darkGray}
              className={cls.confirmButton}
              onClick={onClose}
            >
              Cancel
            </NormalButton>
          </Grid>
          <Grid item>
            <NormalButton
              component={Grid}
              item
              variant="primary"
              color={text === "complete" ? colors.darkGreen : colors.darkRed}
              labelColor={colors.white}
              hoverColor={text === "complete" ? colors.darkerGreen : colors.darkerRed}
              className={cls.confirmButton}
              onClick={onSubmit}
            >
              Confirm Change
            </NormalButton>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}