import React, { useEffect, useState } from "react";
import { colors, Blink, BTypography, Chip, UserAvatar, NormalButton, PopTip, FilledCircleProgress, Expander } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import ReactHtmlParser from "react-html-parser";
import ArtifactWorkflow from "equip/artifact/artifactWorkflow.js";
import { PreviewFileDialog } from "modules";

const useStyles = makeStyles({
  wrapper: {
    paddingBottom: "5rem",
  },
  headerWrapper: {
    maxHeight: "20vh",
    minHeight: "4rem",
    transition: "all 1s ease",
    position: "sticky",
    top: "0",
    bottom: "0",
    zIndex: "101",
    overflow: "auto",
  },
  headerTop: props => ({
    backgroundColor: props.color,
    backgroundImage: props.backgroundURL ? `url("${props.backgroundURL}")` : "",
    backgroundPositionY: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  }),
  headerBottom: props => ({
    backgroundColor: props.color,
    backgroundImage: props.backgroundURL ? `url("${props.backgroundURL}")` : "",
    backgroundPositionY: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    maxHeight: "20vh",
  }),
  shareGroup: {
    backgroundColor: `${colors.white}50`,
    paddingBottom: "0.5rem",
  },
  shareGroupName: {
    paddingTop: "0.5rem",
    textAlign: "center",
    color: colors.white,
  },
  activeUsers: {},
  disabledChip: {
    opacity: "0.5",
  },
  subHead: props => ({
    padding: "1rem 1rem 2rem 1rem",
    color: colors.Black,
    backgroundPositionY: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat"
  }),
  backButtonWrapper: { padding: "0 0.5rem", },
  backButton: {
    padding: "0.5rem",
  },
  programTitleWrapper: {
    padding: "0.5rem 0",
  },
  programTitle: {
    color: colors.white,
    fontWeight: "bold",
    textAlign: "center"
  },
  programSubtitle: {
    color: colors.white,
    textAlign: "center"
  },
  bodyWrapper: props => ({
    maxWidth: "65rem",
    marginTop: "2rem",
    paddingBottom: "4rem",
    borderLeft: `3px solid ${props.color}`,
    overflow: "auto",
  }),
  body: { padding: "1rem", height: "100%", background: colors.white, },
  pathData: { color: colors.white, padding: "0 1rem" },
  dataLabel: { minWidth: "10rem" },
  dataName: { fontWeight: "bold" },
  groupRow: { padding: "1rem 0.5rem" },
  groupName: props => ({ color: props.color, fontWeight: "bold" }),
  groupSubtitle: props => ({ color: props.color }),
  groupDescription: {
    padding: "0 0.5rem",
    color: colors.darkerGray
  },
  itemRow: { padding: "0.75rem 0 0.5rem 0.5rem", borderBottom: `1px solid ${colors.veryLightGray}` },
  itemName: props => ({ color: props.color, fontWeight: "bold" }),
  itemDescription: {
    padding: "0 1rem",
    color: colors.black
  },
  userPathItem: {
    padding: "0 0.25rem 0.75rem 0",
    border: "2px solid transparent"
  },
  pathItemTitle: props => ({
    fontWeight: "bold",
    color: props.color,
  }),
  activeUserPathItem: props => ({
    borderColor: `${props.color} !important`,
  }),
  userPathItemNoFile: {
    padding: "0.15rem"
  },
  usersFiles: {
    padding: "0 1rem"
  },
  usersNoFiles: {
    padding: "0 1rem"
  },
  noFileMessage: {
    fontStyle: "italic",
    padding: "1rem",
  },
  filesHeader: {
    paddingBottom: "1rem",
  },
  usersFile: {
    maxHeight: "50vh",
    overflowY: "auto",
  },
  progressBadge: {
    paddingLeft: "0.5rem"
  },
});

function ReviewSharedPath({ group, users, path, pathItem, pathItemName, pathItemDescription, docId, setDocId, fileLoading }) {
  const backgroundURL = path.backgroundImageURL;
  const color = path.programColor ? path.programColor.webFrontendValue : colors.bildBlue;
  const cls = useStyles({ backgroundURL, color });
  const [currentUser, setCurrentUser] = useState(null);
  const [previewFile, setPreviewFile] = useState(null);
  const [showMembers, setShowMembers] = useState(false);
  const [allPathItemFiles, setAllPathItemFiles] = useState([]);

  useEffect(()=>{
    if (pathItem) {
      let fs = [];

      for (let i=0; i < pathItem.userPathItems.length; i++) {
        let upi = pathItem.userPathItems[i];
        if (upi.userLearningItem.files) {
          for (let j=0; j < upi.userLearningItem.files.length; j++) {
            const cu = upi.userLearningItem.user;
            let cf = upi.userLearningItem.files[j];
            cf.user = cu;
            fs.push(cf);
          }
        }
      }

      setAllPathItemFiles(fs);
    }
  },[pathItem]);

   return (
    <Grid container justifyContent="center" alignItems="flex-start" className={cls.wrapper}>
      <Grid container item xs={12} className={cls.headerWrapper}>
        <Grid container item md={8} sm={7} xs={12} className={cls.headerTop} justifyContent="center" alignItems="center">
          <Grid item className={cls.backButtonWrapper}>
            <NormalButton
              component={Blink}
              dst={!pathItem ? "equip-review-shared-paths" : "equip-review-shared-path"}
              path_id={path.id}
              group_id={group.id}
              variant={"primary"}
              className={cls.backButton}
              labelColor={colors.white}
              hoverLabelColor={colors.white}
              hoverColor={colors.veryLightGray + "50"}
              borderColor={colors.white}
            >
              <i className="fal fa-arrow-left fa-2x" />
            </NormalButton>
          </Grid>
          <Grid container item xs className={cls.programTitleWrapper}>
            <Grid item xs={12}>
              <BTypography variant="h4" className={cls.programTitle}>
                {ReactHtmlParser(path.name)}
              </BTypography>
            </Grid>
            {path.subtitle && (
              <Grid item xs={12}>
                <BTypography variant="subtitle1" className={cls.programSubtitle}>
                  {ReactHtmlParser(path.subtitle)}
                </BTypography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid container item md={4} sm={5} xs={12} className={cls.headerBottom}>
          <Grid container item xs={12} justifyContent={"center"} alignItems={"center"} className={cls.shareGroup}>
            <Grid container item xs={12} className={cls.shareGroupName} justifyContent={"center"} alignItems={"center"}>
              <Grid item>
                <BTypography variant="h5">{group.name}</BTypography>
              </Grid>
              <Grid item>
                <NormalButton onClick={()=>{setShowMembers(!showMembers)}}>
                  <i className={`fas fa-chevron-${showMembers ? "up" : "down"}`} />
                </NormalButton>
              </Grid>
            </Grid>
            {showMembers && (
              <Grid container item xs={12}>
                <Grid container item xs={12} justifyContent={"center"} alignItems={"center"} className={cls.activeUsers}>
                  {users.map((x,i) =>
                    <Grid item key={i}>
                      <Blink dst="equip-user-path" user_path_id={x.userPathId} >
                        <Chip member={x} hideName size={30}/>
                      </Blink>
                    </Grid>
                  )}
                </Grid>
                <Grid container item xs={12} justifyContent={"center"} alignItems={"center"}>
                  {group.users.filter(x => !users.map(y => y.id).includes(x.id)).map((z,j) =>
                    <Grid item key={j} className={cls.disabledChip}>
                      <Chip member={z} hideName size={30} />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} justifyContent="center" alignItems="flex-start" className={cls.bodyWrapper}>
        <Grid container item xs={12} justifyContent="center" alignItems="flex-start" className={cls.body}>
          {pathItemName && (
            <Grid container item xs={12} className={cls.subHead}>
              <Grid item xs={12}>
                <BTypography variant="h5" className={cls.pathItemTitle}>
                  {ReactHtmlParser(pathItemName)}
                </BTypography>
              </Grid>
              <Grid item xs={12}>
                <BTypography variant="body1">
                  {ReactHtmlParser(pathItemDescription)}
                </BTypography>
              </Grid>
            </Grid>
          )}
          {!pathItem && path && path.learningGroups.map(x => {
            return (
              <Grid container item xs={12} className={cls.groupRow} key={x.id}>
                <Grid item xs={12}>
                  <BTypography variant="h6" className={cls.groupName}>
                    {ReactHtmlParser(x.name)}
                  </BTypography>
                </Grid>
                <Grid item xs={12}>
                  <BTypography variant="subtitle1" className={cls.groupSubtitle}>
                    {ReactHtmlParser(x.subtitle)}
                  </BTypography>
                </Grid>
                <Grid item xs={12}>
                  <BTypography variant="body1" className={cls.groupDescription}>
                    {ReactHtmlParser(x.description)}
                  </BTypography>
                </Grid>
                {x.pathItems.map(y => {
                  return (
                    <Grid container item xs={12} className={cls.itemRow} key={y.id} alignItems="center" justifyContent="space-between">
                      <Grid item lg={10} md={9} sm={8} xs={12}>
                        <BTypography className={cls.itemName}>
                          {ReactHtmlParser(y.learningItem.name)} &nbsp;
                          <PopTip text={ReactHtmlParser(y.learningItem.description)} arrowPlacement="right">
                            <i className="fal fa-info-circle" />
                          </PopTip>
                        </BTypography>
                      </Grid>
                      <Grid container item lg={2} md={3} sm={4} xs={12} alignItems={"center"} justifyContent={"flex-end"}>
                        <Grid item>
                          <NormalButton
                            component={Blink}
                            dst={"equip-review-shared-path"}
                            path_id={path.id}
                            group_id={group.id}
                            path_item_id={y.id}
                            variant={"primary"}
                            color={color}
                            labelColor={colors.white}
                          >
                            View Projects
                          </NormalButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            );
          })}
          {pathItem && (
            <Grid container item xs={12}>
              <Grid item sm={8} xs={12} className={cls.usersFiles}>
                <Grid item xs={12} className={cls.filesHeader}>
                  <BTypography variant="h6">Projects:</BTypography>
                </Grid>
                {pathItem.userPathItems.map((x, i) => {
                  const user = x.userLearningItem.user;
                  const hasFiles = x.userLearningItem.files;
                  if (hasFiles) {
                    return (
                      <Grid
                        container
                        item
                        xs={12}
                        className={`${cls.userPathItem} ${currentUser && user.id === currentUser.id ? cls.activeUserPathItem: ""}`}
                        justifyContent="space-between"
                        alignItems="stretch"
                        key={i}
                      >
                        <Grid container item xs={12} alignItems="center">
                          <Grid item>
                            <Blink dst="equip-user-path-item" user_path_item_id={x.id}>
                              <UserAvatar src={user.avatarURL} name={user.name} badgeSrc={user.badgeURL} size={40} />
                            </Blink>
                          </Grid>
                          <PopTip text={x.completed ? `Completed on ${x.completedAt}` : "Started"}>
                            <Grid item className={cls.progressBadge}>
                              <FilledCircleProgress
                                total={1}
                                completed={x.completed ? 1 : 0}
                                started={!x.completed ? 1 : 0}
                                color={color}
                              />
                            </Grid>
                          </PopTip>
                        </Grid>
                        <Grid container item xs={12}>
                          <ArtifactWorkflow
                            userLearningItemId={x.userLearningItem.id}
                            artifacts={x.userLearningItem.files}
                            docId={null}
                            setDocId={(d)=>{
                              setCurrentUser(user);
                              setDocId(d);
                            }}
                            previewFile={null}
                            setPreviewFile={(p)=>{
                              setCurrentUser(user);
                              setPreviewFile(p);
                            }}
                            editable={false}
                            slimTiles
                          />
                        </Grid>
                      </Grid>
                    );
                  }
                })}
                {!pathItem.userPathItems.some((x) => x.userLearningItem.files && x.userLearningItem.files.length > 0) && (
                  <BTypography variant="body1" className={cls.noFileMessage}>No Projects</BTypography>
                )}
              </Grid>
              <Grid container item sm={4} xs={12} className={cls.usersNoFiles} justifyContent="flex-start" alignItems="flex-start">
                <Grid container item xs={12} direction={"row"}>
                  <Grid item xs={12} className={cls.filesHeader}>
                    <BTypography variant="h6">Members:</BTypography>
                  </Grid>
                  {pathItem.userPathItems.map((x, i) => {
                    const user = x.userLearningItem.user;
                    return (
                      <Grid
                        item
                        lg={6}
                        md
                        sm
                        xs={6}
                        className={`${cls.userPathItemNoFile}`}
                        key={i}
                      >
                        <Blink dst="equip-user-path-item" user_path_item_id={x.id}>
                          <Chip member={user} size={30} />
                        </Blink>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <PreviewFileDialog
        currentUser={currentUser}
        docId={docId}
        previewFile={previewFile}
        fileLoading={fileLoading}
        setCurrentUser={setCurrentUser}
        setDocId={setDocId}
        setPreviewFile={setPreviewFile}
        allPreviewFiles={allPathItemFiles}
      />
    </Grid>
  );
}

export default ReviewSharedPath;
