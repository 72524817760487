import React from "react";
import { colors, BTypography, NormalButton } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Dialog, DialogContent, Grid } from "@mui/material";

const useStyles = makeStyles({
  containerWrapper: { padding: "5rem 1rem", overflowX: "hidden" },
  title: { textAlign: "center", padding: "0 0 2rem 0" },
  buttonWrapper: { padding: "1rem" }
});

export default function SelectNewGroupTypeDialog({ open, closeDialog, openNewGroupDialog, openNewCohortDialog }) {
  const cls = useStyles();

  if (open) {
    return (
      <Dialog open={open} onClose={closeDialog} maxWidth={"md"}>
        <DialogContent className={cls.containerWrapper}>
          <Grid container justifyContent="center" alignItems="center" className={cls.form}>
            <Grid item xs={12} className={cls.title}>
              <BTypography variant="h3">Create a new Sharing Group</BTypography>
            </Grid>
            <Grid container item xs={12} justifyContent="center" alignItems="flex-start">
              <Grid container item xs={6} className={cls.buttonWrapper} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  <NormalButton
                    fullWidth
                    onClick={openNewCohortDialog}
                    variant="primary"
                    color={colors.bildBlue}
                    labelColor={colors.white}
                    hoverColor={colors.darkBildBlue}
                  >
                    New Cohort Group
                  </NormalButton>
                </Grid>
                <Grid item xs={12}>
                  <br/>
                  <i>Create a new Sharing Group and assign Programs to People. (<b>Recommended</b> for most cases)</i>
                </Grid>
              </Grid>
              <Grid container item xs={6} className={cls.buttonWrapper} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  <NormalButton
                    fullWidth
                    onClick={openNewGroupDialog}
                    variant="primary"
                    color={colors.bildBlue}
                    labelColor={colors.white}
                    hoverColor={colors.darkBildBlue}
                  >
                    New Basic Group
                  </NormalButton>
                </Grid>
                <Grid item xs={12}>
                  <br/>
                  <i>Create a new Sharing Group with the <u>option</u> to also assign Programs to People.</i>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  } else {
    return null;
  }
}
