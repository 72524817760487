import React from "react";
import { colors, FormField } from "bild-ui";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  labelWrapper: { color: colors.bildBlue },
  field: { width: "100%", padding: "1rem 0.75rem" },
  errorWrapper: { color: colors.red, "& fieldset": { borderColor: colors.red } }
});

export default function FormDateField({ label, value, setValue, disabled, error, required, classes, color }) {
  const cls = useStyles();

  return (
    <FormField
      label={label}
      color={color}
      error={error}
      required={required}
      child={
        <input
          type="date"
          value={value}
          onChange={e => {
            if (!disabled) {
              setValue(e.target.value);
            }
          }}
          className={`${cls.field} ${classes}`}
          disabled={disabled}
        />
      }
    />
  );
}
