import React, { useState, useEffect } from "react";
import { colors, NormalButton, MembersChecklist } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Dialog, DialogContent, Grid, Select, MenuItem, TextField } from "@mui/material";

const useStyles = makeStyles({
  filterWrapper: { padding: "1rem 0" },
  filter: { width: "100%" },
  users: { overflowY: "auto", minHeight: "20vh", maxHeight: "40vh", border: `1px solid ${colors.veryLightBlue}`, borderRadius: "4px" },
  toggleAll: { paddingTop: "0.5rem" },
  button: { margin: "1rem 0" },
  option: { paddingTop: "1rem" }
});

function CreateChannelDialog({ open, closeDialog, user, recipients, createChannel }) {
  const cls = useStyles();
  const [filter, setFilter] = useState("");
  const [users, setUsers] = useState([]);
  const [name, setName] = useState("");
  const [type, setType] = useState("1");

  useEffect(() => {
    setFilter("");
    setUsers([]);
    setName("");
    setType("1");
  }, [open]);

  function _createChannel() {
    createChannel(name, users, type);
    closeDialog();
  }

  function changeUser(userId) {
    let index = users.indexOf(userId);
    if (index === -1) {
      setUsers([...users, userId]);
    } else {
      setUsers(users.filter(u => u !== userId));
    }
  }

  function toggleAll() {
    if (recipients.length === users.length) {
      setUsers([]);
    } else {
      setUsers(recipients.map(r => r.user.id));
    }
  }

  if (open) {
    return (
      <Dialog open={open} onClose={closeDialog} maxWidth="sm" fullWidth={true}>
        <DialogContent>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} className={cls.filterWrapper}>
              <TextField
                variant="outlined"
                placeholder="filter"
                value={filter}
                onChange={e => {
                  setFilter(e.target.value);
                }}
                className={cls.filter}
              />
            </Grid>
            <Grid item xs={12} className={cls.users}>
              <MembersChecklist
                user={user}
                members={recipients.filter(r => (filter === "" ? r : r.user.name.toLowerCase().includes(filter.toLowerCase())))}
                selectedMembers={users}
                updateMember={changeUser}
              />
            </Grid>
            <Grid container item xs={12} justifyContent="flex-end" alignItems="center" className={cls.toggleAll}>
              {!filter && (
                <Grid item>
                  <NormalButton onClick={toggleAll} variant="primary" labelColor={colors.black} borderColor={colors.darkerGray} hoverLabelColor={colors.black}>
                    {recipients.length === users.length ? "Deselect All" : "Select All"}
                  </NormalButton>
                </Grid>
              )}
            </Grid>
            <Grid container item xs={12} className={cls.option}>
              <Grid item>
                <Select
                  variant="outlined"
                  value={type}
                  onChange={e => {
                    setType(e.target.value);
                  }}
                >
                  <MenuItem value="1">Direct</MenuItem>
                  <MenuItem value="2">Group/Class</MenuItem>
                </Select>
              </Grid>
              {type === "2" && (
                <Grid item xs>
                  <TextField
                    value={name}
                    variant="outlined"
                    placeholder="Name"
                    onChange={e => {
                      setName(e.target.value);
                    }}
                    fullWidth
                  />
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} className={cls.button}>
              <NormalButton onClick={_createChannel} variant="primary" labelColor={colors.black} borderColor={colors.darkerGray} hoverLabelColor={colors.black} fullWidth={true}>
                Create
              </NormalButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  } else {
    return null;
  }
}

export default CreateChannelDialog;
