import React from "react";
import { makeStyles } from "@mui/styles";
import { colors } from "bild-ui";
import { TextField, InputAdornment } from "@mui/material";

const useStyles = makeStyles({
  filter: props => ({
    background: colors.white,
    minWidth: `${props.fullWidth ? "100%" : "15vw"}`,
    width: `${props.fullWidth ? "100%" : "auto"}`,
    padding: `${props.wrapperPadding ? props.wrapperPadding : "0 0 0.5rem 0 !important"}`,
    "& input": { padding: `${props.inputPadding ? props.inputPadding : "0.75rem !important"}` }
  }),
  clear: {
    padding: "0.5rem 0.75rem 0.5rem 0.5rem",
    color: colors.darkGray,
    "&:hover": { cursor: "pointer", color: colors.black }
  },
  empty: {
    color: "transparent !important"
  }
});

function FilterTextField({ value, placeholder, onChange, onClear, wrapperPadding, inputPadding, fullWidth }) {
  const cls = useStyles({ wrapperPadding, inputPadding, fullWidth });

  return (
    <TextField
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      variant="outlined"
      className={cls.filter}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" onClick={onClear}>
            <i className={`${cls.clear} fal  fa-times-circle ${value && value.length > 0 ? "" : cls.empty}`} />
          </InputAdornment>
        )
      }}
    />
  );
}

export default FilterTextField;
