import { getRequest, putRequest, deleteRequest } from "../core/comm.js";

export function attachArtifact(competency_id, user_id, attachments, success_cb, error_cb) {
  putRequest(`/competencies/${competency_id}/users/${user_id}/files`, attachments, success_cb, null, error_cb);
}

export function removeArtifact(competency_id, user_id, attachment, success_cb, error_cb) {
  deleteRequest(`/competencies/${competency_id}/users/${user_id}/files`, attachment, success_cb, null, error_cb);
}

export function submitProof(proof_id, data, isAfTraining, success_cb, error_cb) {
  if (isAfTraining) {
    putRequest(`/competencies/proofs/${proof_id}/assessments/training`, data, success_cb, null, error_cb);
  } else {
    putRequest(`/competencies/proofs/${proof_id}/assessments`, data, success_cb, null, error_cb);
  }
}

export function updateCompetencyProof(proof_id, data, success_cb, error_cb) {
  putRequest(`/competencies/proofs/${proof_id}`, data, success_cb, null, error_cb);
}

export function loadFromEnrollment(course_id, enrollment_id, success_cb, error_cb) {
  getRequest(`/courses/${course_id}/enrollments/${enrollment_id}`, success_cb, null, error_cb);
}

export function loadFromProof(course_id, proof_id, success_cb, error_cb) {
  getRequest(`/courses/${course_id}/competency_proofs/${proof_id}`, success_cb, null, error_cb);
}

export function loadFromUserAndCompetencySet(course_id, user_id, competency_set_id, success_cb, error_cb) {
  getRequest(`/courses/${course_id}/users/${user_id}/competency_sets/${competency_set_id}`, success_cb, null, error_cb);
}

export function loadCompetencyProof(course_id, proof_id, success_cb, error_cb) {
  getRequest(`/courses/${course_id}/competency_proofs/${proof_id}`, success_cb, null, error_cb);
}

export function loadCompetencySets(course_id, success_cb, error_cb) {
  getRequest(`/courses/${course_id}/competency_sets`, success_cb, null, error_cb);
}

export function switchCompetencySet(course_id, data, success_cb, error_cb) {
  putRequest(`/courses/${course_id}/competency_sets`, data, success_cb, null, error_cb);
}

export function loadAfTraineeQuestions(success_cb, error_cb) {
  // TODO move this to pull from a VisualList LocalStorage ENUM
  getRequest(`/help/visualList`, success_cb, null, error_cb);
}

export function loadCurrentTraineeAssessment(competency_proof_id, success_cb, error_cb) {
  getRequest(`/competencies/proofs/${competency_proof_id}/current_training_assessment`, success_cb, null, error_cb);
}

export function loadEnrollmentDashboard(enrollment_id, success_cb, error_cb) {
  // TODO: should this call the enrollment.js data object's loadDashboard ?
  getRequest(`/enrollments/${enrollment_id}/dashboard/`, success_cb, null, error_cb);
}

export function loadAllCompetencyProofs(user_id, success_cb, error_cb) {
  getRequest(
    `/courses/users/${user_id}/competency_proofs`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    error_cb
  );
}