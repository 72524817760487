import React, { useState, useEffect } from "react";
import { managerData, userData } from "bild-data";
import { access, hideIntercom, setPageTitle } from "bild-utils";
import { LoadingScreen } from "bild-ui";
import { toaster } from "presentational/toasts/toasts.js";
import ManageGroup from "./manageGroup.js";

function ManageGroupLoader({ startGroupId, onCloseDialog }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [users, setUsers] = useState([]);
  const [paths, setPaths] = useState([]);
  const [groups, setGroups] = useState([]);
  const [groupId, setGroupId] = useState(startGroupId ? startGroupId : null);
  const userOverview = userData.getOverview();
  const [userPreferences, setUserPreferences] = useState(JSON.parse(userOverview.userPreferences));
  const [favoriteGroups, setFavoriteGroups] = useState(access(userPreferences, "sharingGroups.favoriteGroups", []));
  const [archiveGroups, setArchiveGroups] = useState(access(userPreferences, "sharingGroups.archiveGroups", []));

  useEffect(() => {
    hideIntercom();

    function _setData(d) {
      setPageTitle("Manage Groups");
      _setGroups(d.sharingGroups);
      setLoading(false);
    }
    function _setAvailableUsers(m) {
      setUsers(m.users);
    }
    function _setAvailablePaths(p) {
      setPaths(p.paths);
    }

    managerData.getManagedGroups(_setData, e => {
      setError(e.response.data.error);
    });
    managerData.getManagedUsers(_setAvailableUsers, e => {
      setError(e.response.data.error);
    });
    managerData.getManagedPaths(_setAvailablePaths, e => {
      setError(e.response.data.error);
    });
  }, []);

  function _setGroups(g) {
    // Save old, other channel messages for quicker loading, but override all other data from new channel info
    let newGroups = g;

    // Sort by favorites first
    let favItems = [];
    let normItems = [];
    let inactiveItems = [];
    for (let i=0; newGroups.length > i; i++) {
      let c = newGroups[i];

      if (archiveGroups && archiveGroups.length > 0 && archiveGroups.includes(c.id)) {
        // Don't add Archive Groups
        inactiveItems.push(c);
      } else {
        // Add Normal Groups
        if (favoriteGroups && favoriteGroups.length > 0 && favoriteGroups.includes(c.id)) {
          favItems.push(c);
        } else {
          normItems.push(c);
        }
      }
    }
    
    // Finally Set Groups
    setGroups(favItems.concat(normItems).concat(inactiveItems));
  }

  function createUpdateGroup(group_id, name, new_paths, remove_paths, new_users, remove_users, assignPrograms, createGroup, shepherdId) {
    let data = {
      sharingGroups: [
        {
          id: group_id,
          name: name,
          paths: new_paths.map(x => ({ id: x })),
          pathsToRemove: remove_paths.map(x => ({ id: x })),
          users: new_users.map(x => ({ id: x })),
          usersToRemove: remove_users.map(x => ({ id: x })),
          assignProgramsToMembers: assignPrograms,
          createSharingGroup: createGroup,
          shepherdUserId: shepherdId
        }
      ]
    };
    managerData.updateManagedSharingGroups(data, d => {
      if (group_id) {
        toaster.success(`${name} Updated!`);
      } else {
        toaster.success(`${name} Created!`);
      }
      _setGroups(d.sharingGroups);
    });
  }

  function createUpdateCohort(group_id, name, new_paths, new_users, new_user_paths, createGroup) {
    let data = {
      sharingGroups: [
        {
          id: group_id,
          name: name,
          paths: new_paths.map(x => ({ id: x })),
          users: new_users.map(x => ({ id: x })),
          userPaths: new_user_paths.map(x => ({ pathId: x.programId, user: { id: x.userId }, shepherdUser: { id: x.shepherdUserId } })),
          createSharingGroup: true
        }
      ]
    };
    managerData.updateManagedSharingGroups(data, d => {
      if (group_id) {
        toaster.success(`${name} Updated!`);
      } else {
        toaster.success(`${name} Created!`);
      }
      _setGroups(d.sharingGroups);
    });
  }

  function toggleFavoriteGroup(id) {
      // figure out toggle false or true
      let toggleDirection = favoriteGroups.includes(id) ? false : true;
      // Set local Favorite Groups to new value
      if (toggleDirection) {
        setFavoriteGroups([...favoriteGroups, id]);
      } else {
        setFavoriteGroups(favoriteGroups.filter(x => x !== id));
      }
  
      let newData = {
        sharingGroups: [{ id: id, userFavorite: toggleDirection }]
      };
      managerData.updateManagedSharingGroups(
        newData,
        (d) => {
          _setGroups(d.sharingGroups);
          userData.loadOverview((newUO)=>{
            setUserPreferences(JSON.parse(newUO.userPreferences));
          });
        }
      );
    }
  
    function toggleArchiveGroup(id) {
      // figure out toggle false or true
      let toggleDirection = archiveGroups.includes(id) ? false : true;
      // Set local Archive Groups to new value
      if (toggleDirection) {
        setArchiveGroups([...archiveGroups, id]);
      } else {
        setArchiveGroups(archiveGroups.filter(x => x !== id));
      }
  
      let newData = {
        sharingGroups: [{ id: id, userArchive: toggleDirection }]
      };
      managerData.updateManagedSharingGroups(
        newData,
        (d) => {
          _setGroups(d.sharingGroups);
          userData.loadOverview((newUO)=>{
            setUserPreferences(JSON.parse(newUO.userPreferences));
          });
        }
      );
    }

  if (loading || error) return <LoadingScreen error={error} />;
  return (
    <ManageGroup
      users={users}
      paths={paths}
      groups={groups}
      startGroupId={groupId}
      createUpdateGroup={createUpdateGroup}
      createUpdateCohort={createUpdateCohort}
      favoriteGroups={favoriteGroups}
      archiveGroups={archiveGroups}
      toggleFavoriteGroup={toggleFavoriteGroup}
      toggleArchiveGroup={toggleArchiveGroup}
      onCloseDialog={onCloseDialog}
    />
  );
}

export default ManageGroupLoader;
